import React from "react";
import { Route, Redirect } from 'react-router-dom';
import Home from "./Home";

import { useGlobal } from 'reactn';

const Routes = (props) => {
    
    const [ web3Instance ] = useGlobal('web3Instance');
    
    return (
            <div className="application-body">
                <Route exact path='/' component={Home}/>
                <Route exact path='/app' component={Home}/>
            </div>
    )
}

export default Routes