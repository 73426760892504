import { Web3Util, Accounts } from './index';
import ERC20 from "../contracts/ERC20.json";
import EasyBid from "../contracts/EasyBid.json";


async function getEasyBidContractInstance(){
    if(!window.easyBidCI) {
        
    }
    const instance = await Web3Util.getInstance();
    window.easyBidCI = new instance.eth.Contract(
        EasyBid.abi,
        "0xEa3c336c34C34C78BC7Fa2f3D16957B8d0aE6904"
    );
    return window.easyBidCI
}

async function getERCContractInstance(address){
    if(!window.ercCI) {
        
    }
    const instance = await Web3Util.getInstance();
        window.ercCI = new instance.eth.Contract(
            ERC20.abi,
            address
        );
    return window.ercCI
}

async function getETHAllowance(){
    let account = await Accounts.getCurrentAccount();
    let instance = await getEasyBidContractInstance();

    let ethAllowance = await instance.methods.getETHAllowance(account).call();
    return ethAllowance;
  }

async function startSwap(token, ethUsed, profitLoss){
    let account = await Accounts.getCurrentAccount();
    let instance = await getEasyBidContractInstance();
    let swap = await instance.methods.createBid(
        token, ethUsed, profitLoss
    ).send({
        from: account
    });
    return swap;
  }

async function callBid(bidder, index){
    let account = await Accounts.getCurrentAccount();
    let instance = await getEasyBidContractInstance();
    let call = await instance.methods.callBid(
        bidder, index
    ).send({
        from: account
    });
    return call;
}

async function getBids(){
    let account = await Accounts.getCurrentAccount();
    let instance = await getEasyBidContractInstance();

    let bids = await instance.methods.getBids().call();
    return bids;
}

async function getBidDetails(bidAddress){
    let account = await Accounts.getCurrentAccount();
    let instance = await getEasyBidContractInstance();
    let bids = await instance.methods.getBidDetail(bidAddress).call();
    return bids;
}

async function getTokenName(address){
    let account = await Accounts.getCurrentAccount();
    console.log(account);
    let instance = await getERCContractInstance(address);
    let name = await instance.methods.name().call();
    return name;
}

export {
    getEasyBidContractInstance,
    getETHAllowance,
    getTokenName,
    startSwap,
    callBid,
    getBids,
    getBidDetails
}