import React, { useEffect, useState, Component } from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Badge } from 'react-bootstrap';
import { Web3Util, Accounts, Contracts } from '../utils/index';
import ERC20 from "../contracts/ERC20.json";


import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";

// import { Swap, Balances } from '../utils';

export default class Home extends Component {

    state = {
        myAccount: "",
        loaded: false,
        currentArea: "bid",
        allowance: 0,
        ethToUse: 0,
        endToken: "Enter a Token",
        endAddress: "",
        profitLoss: 0,
        bids: []
    };
      
      handleClick = () => {
        this.props.toggle();
      };

      setArea(area) {
        let prevEl = document.getElementById(this.state.currentArea);
        prevEl.classList.remove('n-selected');
        this.setState({currentArea: area});
        let el = document.getElementById(area);
        el.classList.add('n-selected');
      };

      myAllowance = async () => {
        let allowance = await Contracts.getETHAllowance();
        return allowance;
      }

      getMyAccount = async () => {
        this.setState({myAccount: await Accounts.getCurrentAccount()})
      }

      setETHUsed(e) {
        this.setState({ethToUse: e.target.value})
      }

      setEndToken = async (e) => {
        let name = await Contracts.getTokenName(e.target.value)
        this.setState({endToken: name, endAddress: e.target.value})
      }

      setProfitLoss(e) {
        this.setState({profitLoss: e.target.value});
      }

      sendSwap = async () => {
        await Contracts.startSwap(
          this.state.endAddress,
          Web3.utils.toWei(this.state.ethToUse.toString()),
          this.state.profitLoss
        )
      }

      callSwap = async (bidder, index) => {
        await Contracts.callBid(
          bidder, index
        )
      }

      getBids = async () => {
        let bidAddresses = await Contracts.getBids()
        let bidArr = this.state.bids;
        for (let i = 0; i < bidAddresses.length; i++) {
          try {
            bidArr.push(await Contracts.getBidDetails(bidAddresses[i]))
            
          } catch(error) {
            
          }
        }
        this.setState({bids: bidArr})
      }

      loadBids() {
        let arr = [];
        for(let i = 0; i < this.state.bids.length; i++) {
          let profit = Number(this.state.bids[i][3]) - Number(this.state.bids[i][0]);
          arr.push(
            <div className="bid">
              {/* <div>{Web3.utils.fromGwei(this.state.bids[i][2].toString())}</div> */}
              <div className="bid-token">{this.state.bids[i][1]}</div>
              <div>
                <div className="bold">ETH Used:</div> 
                {Web3.utils.fromWei(this.state.bids[i][0].toString())}
              </div>
              <div>
                <div className="bold">Current ETH Profit:</div> 
                {profit > 0 ?
                  <div className="in-profit">{Web3.utils.fromWei(profit.toString())}</div>
                  :
                  <div className="in-loss">{Web3.utils.fromWei(profit.toString())}</div>
                }
              </div>
              {this.state.bids[i][4]|| this.state.bids[i][5] == this.state.myAccount ? 
                <div className="call-button" onClick={() => this.callSwap(this.state.bids[i][5], i)}>CALL</div>
                :
              <div className="call-low-button">Profit/Loss too low</div>
              }
            </div>
          )
        }
        return arr;
      }

      connect = async () => {
        let instance = await Web3Util.createInstance();
        if (instance) {
          await Accounts.getCurrentAccount()
          this.setState({allowance: await this.myAllowance()})
        }
    }
    
    
    
      componentDidMount = async () => {
    
        try {
          this.connect();
          this.getBids();
          this.getMyAccount();
        //   this.web3 = getWeb3Var();
            
        //   // Get network provider and web3 instance.
         
        //   // Use web3 to get the user's accounts.
        //   this.accounts = await this.web3.eth.getAccounts();
        
        //   // Get the contract instance.
        //   this.networkId = await this.web3.eth.net.getId();
    
        //   this.nyanInstance = new this.web3.eth.Contract(
        //     NyanToken.abi,
        //     process.env.REACT_APP_NYAN_TOKEN_CONTRACT_ADDRESS
        //   );
         
        //   this.catnipInstance = new this.web3.eth.Contract(
        //     CatnipToken.abi,
        //     process.env.REACT_APP_CATNIP_TOKEN_CONTRACT_ADDRESS
        //   );
         
          
          this.setArea('bid');
          // Set web3, accounts, and contract to the state, and then proceed with an
          // example of interacting with the contract's methods.
          // console.log(await this.myAllowance);
          
          this.setState({loaded: true});
          
        } catch (error) {
          // Catch any errors for any of the above operations.
          alert(
            `Failed to load web3, accounts, or contract. Check console for details.`,
          );
          console.error(error);
        }
      };
      render() {
        return (
          <div>
              <div className="nav-container">
                <div className="access-info">
                    ETH Allowance: {Web3.utils.fromWei(this.state.allowance.toString())}
                </div>
                <div onClick={() => this.setArea('bid')} id="bid" className="option">BID</div>
                <div onClick={() => this.setArea('call')} id="call" className="option">CALL</div>
              </div>
              {this.state.currentArea === 'bid' ?
                <div>
                    <div className="swap-text">SWAP</div>
                    <div className="start-token-container">
                        <div className="start-token-info">
                            <input className="start-token-input" 
                            type="number" placeholder="How much" onChange={this.setETHUsed.bind(this)}></input>
                            <div className="start-ETH">
                                ETH
                            </div>
                        </div>
                    </div>
                    <div className="for-text">FOR</div>
                    <div className="end-token-container">
                        <div className="end-token-info">
                            <div className="end-token-input-container">
                                <input placeholder="Enter token's address" 
                                className="end-token-address-container"
                                onChange={this.setEndToken.bind(this)}>

                                </input>
                            </div>
                            {this.state.endToken}
                        </div>
                    </div>
                    <div className="profit-loss-text">Profit/Loss %</div>
                    <div className="profit-loss-container">
                    <div className="profit-loss-info">
                        <input type="number" 
                        placeholder="Enter amount %" 
                        className="profit-loss-input"
                        onChange={this.setProfitLoss.bind(this)}></input>
                    </div>
                    </div>
                    <div className="send-it" onClick={() => this.sendSwap()}>Send it!</div>
                </div>
                :
                <div>
                    <div className="call-info">
                        Want to make quick ETH? Nyan-2 holders with at least 1 Nyan-2 can trigger calls
                        in order to get a % of the profits or losses.
          
                    </div>
                    <div className="bid-container">
                      {this.loadBids()}
                    </div>
                </div>
              }
          </div>
        );
      }
    }